/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Skeleton,
  SkeletonBlock
} from '@one-thd/sui-atomic-components';
import { ProductOverviewAccordionTitle } from '@thd-olt-component-react/product-overview';
import { SpecificationsAccordionTitle } from '@thd-olt-component-react/specifications';
import {
  QuestionsAndAnswersAccordionTitle,
  QuestionsAndAnswersAccordionBody
} from '@thd-olt-component-react/questions-and-answers';
import {
  RatingsAndReviewsAccordionTitle,
  RatingsAndReviewsAccordionBody
} from '@thd-olt-component-react/ratings-and-reviews';
import {
  object, shape as shapeProp, string, bool, arrayOf
} from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { BundleProductDetails } from './BundleProductDetails';
import { BundleSpecifications } from './BundleSpecifications';
import { isMajorAppliances } from '../utils/product-bundle-utils';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {array} products - list of products that are in the bundle
 * @property {string} selectedSpecProductBundleItemId - Id selected from the product bundle list
 */
/**
 * Properties of a product
 *
 * @typedef {object} Product
 * @property {string} itemId The identifier of the element
 * @property {string} identifiers Contains the title to show on the product list
 */
/**
/**
 * handleAccordionChange:
 * @param {string} accordion - name of accordion changed.
 * @param {boolean} [canCloseAccordion=true] - Indicates if accordion can be closed, default true.
 * @returns {void}
 */

/**
 * refs - Object containing references to various DOM elements.
 * @typedef {Object} Refs
 * @property {React.RefObject} productOverviewRef - Reference to the product overview element.
 * @property {React.RefObject} specificationsRef - Reference to the specifications element.
 * @property {React.RefObject} questionsRef - Reference to the questions element.
 * @property {React.RefObject} ratingsRef - Reference to the ratings element.
 */

/**
 * isHDPPSku - Indicates whether the SKU is an HDPP SKU.
 * @type {boolean}
 */

/**
 *expanded -  Represents the state of accordion items, indicating whether each accordion is expanded or not.
 * @type {Object<string, boolean>}
 */

/**
 * thdSeoBotDetected - Indicates whether the THD SEO bot is detected.
 * @type {boolean}
 */

/**
 * isMA-  Indicates whether the product is classified as Major Appliances.
 * @type {boolean}
 */

const BundleAccordions = ({
  itemId,
  products,
  product,
  isHDPPSku,
  thdSeoBotDetected,
  accordionsRefs
}) => {
  const isMA = isMajorAppliances(product);
  const [expanded, setExpanded] = useState({});

  const handleAccordionChange = (accordionId) => {
    setExpanded((prevState) => ({ ...prevState, [accordionId]: !expanded[accordionId] }));
  };

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.on('product-bundle.open-accordion', ({ output }) => {
      setExpanded((prevState) => ({ ...prevState, [output]: true }));
    });
  }, []);

  return (
    <div data-testid="bundle-accordions">
      <div
        id="product-section-overview"
        data-testid="product-section-overview"
        ref={accordionsRefs.productOverviewRef}
      >
        <Accordion
          key="product-details-accordion"
          expanded={expanded['#product-section-overview'] || thdSeoBotDetected}
          onChange={() => handleAccordionChange('#product-section-overview')}
        >
          <AccordionHeader
            aria-controls="accordion1-content"
            id="accordion1-header"
            data-testid="accordion1-header"
          >
            <div className="sui-flex sui-flex-col sui-justify-center sui-min-h-12 sui-w-full">
              <ProductOverviewAccordionTitle
                hydrator={{
                  placeholder: (
                    <Skeleton
                      disablePadding
                      SkeletonContentProps={{
                        grow: true,
                        orientation: 'horizontal',
                        initialSize: 'full'
                      }}
                    >
                      <SkeletonBlock height={10} aspect="wide" />
                    </Skeleton>
                  )
                }}
              />
            </div>
          </AccordionHeader>
          <AccordionBody>
            <BundleProductDetails products={products} />
          </AccordionBody>
        </Accordion>
      </div>
      <div id="product-section-key-feat" ref={accordionsRefs.specificationsRef}>
        <Accordion
          key="specifications-accordion"
          expanded={expanded['#product-section-key-feat'] || thdSeoBotDetected}
          onChange={() => handleAccordionChange('#product-section-key-feat')}
        >
          <AccordionHeader
            aria-controls="accordion2-content"
            id="accordion2-header"
            data-testid="accordion2-header"
          >
            <div className="sui-flex sui-flex-col sui-justify-center sui-min-h-12 sui-w-full">
              <SpecificationsAccordionTitle
                itemId={itemId}
                showSubtitle={false}
                hydrator={{
                  placeholder: (
                    <Skeleton
                      disablePadding
                      SkeletonContentProps={{
                        grow: true,
                        orientation: 'horizontal',
                        initialSize: 'full'
                      }}
                    >
                      <SkeletonBlock height={10} aspect="wide" />
                    </Skeleton>
                  )
                }}
              />
            </div>
          </AccordionHeader>
          <AccordionBody>
            <BundleSpecifications products={products} />
          </AccordionBody>
        </Accordion>
      </div>

      {!isMA && (
        <>
          <div id="product-section-qa" ref={accordionsRefs.questionsRef}>
            <Accordion
              key="questions-answers-accordion"
              expanded={expanded['#product-section-qa'] || thdSeoBotDetected}
              onChange={() => handleAccordionChange('#product-section-qa')}
            >
              <AccordionHeader
                aria-controls="accordion3-content"
                id="accordion3-header"
                data-testid="accordion3-header"
              >
                <div className="sui-flex sui-flex-col sui-min-h-10 sui-justify-center sui-w-full">
                  <QuestionsAndAnswersAccordionTitle
                    noSpacing
                    itemId={itemId}
                    hydrator={{
                      placeholder: (
                        <Skeleton
                          disablePadding
                          SkeletonContentProps={{
                            orientation: 'horizontal',
                            initialSize: 'full'
                          }}
                        >
                          <SkeletonBlock height={10} aspect="wide" />
                        </Skeleton>
                      )
                    }}
                    dynamic={{
                      pageType: 'pip'
                    }}
                  />
                </div>
              </AccordionHeader>
              <AccordionBody data-testid="questions-and-answers-body">
                <QuestionsAndAnswersAccordionBody itemId={itemId} seoPageNumber={1} />
              </AccordionBody>
            </Accordion>
          </div>

          {!isHDPPSku && (
            <div id="product-section-rr" className="sui-w-full" ref={accordionsRefs.ratingsRef}>
              <Accordion
                key="ratings-reviews-accordion"
                expanded={expanded['#product-section-rr'] || thdSeoBotDetected}
                onChange={() => handleAccordionChange('#product-section-rr')}
              >
                <AccordionHeader
                  aria-controls="accordion4-content"
                  id="accordion4-header"
                  data-testid="accordion4-header"
                >
                  <div className="sui-flex sui-flex-col sui-min-h-10 sui-justify-center sui-w-full">
                    <RatingsAndReviewsAccordionTitle
                      noSpacing
                      itemId={itemId}
                      hydrator={{
                        placeholder: (
                          <Skeleton
                            disablePadding
                            SkeletonContentProps={{
                              grow: true,
                              orientation: 'horizontal',
                              initialSize: 'full'
                            }}
                          >
                            <SkeletonBlock height={10} aspect="wide" />
                          </Skeleton>
                        )
                      }}
                      dynamic={{
                        pageType: 'pip'
                      }}
                    />
                  </div>
                </AccordionHeader>
                <AccordionBody data-testid="reviews-body">
                  <RatingsAndReviewsAccordionBody itemId={itemId} prosAndCons />
                </AccordionBody>
              </Accordion>
            </div>
          )}
        </>
      )}
    </div>
  );
};

BundleAccordions.propTypes = {
  itemId: string,
  product: shapeProp({ object }),
  products: arrayOf(shapeProp({ object })),
  isHDPPSku: bool,
  thdSeoBotDetected: bool,
  accordionsRefs: shapeProp({
    productOverviewRef: shapeProp({ current: shapeProp({}) }),
    specificationsRef: shapeProp({ current: shapeProp({}) }),
    questionsRef: shapeProp({ current: shapeProp({}) }),
    ratingsRef: shapeProp({ current: shapeProp({}) }),
    stickyNavRef: shapeProp({ current: shapeProp({}) })
  })
};

BundleAccordions.defaultProps = {
  itemId: null,
  products: null,
  product: null,
  isHDPPSku: false,
  thdSeoBotDetected: false,
  accordionsRefs: {}
};

BundleAccordions.displayName = 'BundleAccordions';

BundleAccordions.dataModel = extend(
  QuestionsAndAnswersAccordionBody,
  RatingsAndReviewsAccordionBody,
  BundleProductDetails,
  BundleSpecifications
);

export { BundleAccordions };
