import React, { useContext } from 'react';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import {
  arrayOf as arrayOfProp,
  bool as boolProp,
  shape as shapeProp
} from 'prop-types';
import {
  params, string, arrayOf, shape, extend
} from '@thd-nucleus/data-sources';
import { MediaGalleryDesktop, MediaGalleryMobile, MediaGalleryCarousel } from '@thd-olt-component-react/media-gallery';
import { getBundleMedia, getIsMobile } from '../utils/product-bundle-utils';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {object} product The main product
 * @property {array} products An array of products in a bundle
 * @property {bool} showLoader Show loader flag
 */

/**
 * Returns a media gallery including images, video and three-sixty
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const BundleMediaGallery = ({
  product,
  products,
  showLoader
}) => {
  const experienceContext = useContext(ExperienceContext);
  const fsFilmstrip = useConfigService('fs:isFilmstripEnable');
  const { channel } = experienceContext;
  let bundleMedia = null;

  if (product && products) {
    bundleMedia = getBundleMedia(product, products);
  }

  const getMobileComponent = (bundleMediaData, showLoaderFlag) => {

    if (fsFilmstrip) {
      return (
        <div data-component="BundleMediaGalleryCarousel" data-testid="bundle-media-gallery-carousel">
          <MediaGalleryCarousel
            showLoader={showLoaderFlag}
            overrideData={bundleMediaData}
            mainImageFetchPriority="high"
            overlayType="filmstrip"
            itemId={product?.itemId}
          />
        </div>
      );
    }
    return (
      <div data-component="BundleMediaGalleryMobile" data-testid="bundle-media-gallery-mobile">
        <MediaGalleryMobile
          showLoader={showLoaderFlag}
          overrideData={bundleMediaData}
          itemId={product?.itemId}
        />
      </div>
    );
  };

  return (
    <div data-component="BundleMediaGallery" data-testid="bundle-media-gallery">
      {getIsMobile(channel) ? (
        getMobileComponent(bundleMedia, showLoader)
      ) : (
        <div data-component="BundleMediaGalleryDesktop" data-testid="bundle-media-gallery-desktop">
          <MediaGalleryDesktop
            showLoader={showLoader}
            overrideData={bundleMedia}
            mainImageFetchPriority="high"
            itemId={product?.itemId}
          />
        </div>
      )}
    </div>
  );
};

BundleMediaGallery.propTypes = {
  showLoader: boolProp,
  product: shapeProp({
    media: shapeProp()
  }),
  products: arrayOfProp(shapeProp())
};

BundleMediaGallery.defaultProps = {
  product: null,
  products: null,
  showLoader: false
};

BundleMediaGallery.displayName = 'BundleMediaGallery';

BundleMediaGallery.dataModel = extend(
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      media: shape({
        images: arrayOf({
          sizes: arrayOf(string()),
          subType: string(),
          type: string(),
          url: string()
        }),
        threeSixty: arrayOf({
          id: string(),
          url: string()
        }),
        video: arrayOf({
          shortDescription: string(),
          title: string(),
          thumbnail: string(),
          type: string(),
          url: string(),
          videoId: string(),
          videoStill: string()
        })
      })
    })
  },
  MediaGalleryDesktop,
  MediaGalleryMobile
);

export { BundleMediaGallery };
