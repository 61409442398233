/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import { string as propTypeString, bool as propTypeBool } from 'prop-types';
import {
  params, string, arrayOf, extend, shape
} from '@thd-nucleus/data-sources';

import { useBundleProducts } from '../hooks/useBundleProducts';
import useMajorAppliances from '../hooks/useMajorAppliances';

const dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      itemId: string(),
      bundleSpecificationDetails: shape({
        type: string(),
        components: arrayOf(shape({
          id: string(),
          defaultProductId: string(),
          quantity: string(),
          type: string()
        }))
      })
    })
  },
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      itemId: string()
    })
  }
);

export const withBundleProducts = (BaseComponent) => {

  const BundleComponent = ({ bundleId, storeId, listenToProductsChange, ...rest }) => {
    const isMABundleEnabled = useConfigService('isMABundleEnabled');
    const bundleProducts = useBundleProducts(bundleId, storeId, listenToProductsChange, isMABundleEnabled);
    const majorApplianceData = useMajorAppliances(bundleProducts.product, bundleProducts.products);

    return (
      <BaseComponent
        majorApplianceData={majorApplianceData}
        {...bundleProducts}
        {...rest}
      />
    );
  };

  BundleComponent.dataModel = BaseComponent?.dataModel
    ? extend(dataModel, BaseComponent?.dataModel)
    : dataModel;

  BundleComponent.propTypes = {
    bundleId: propTypeString.isRequired,
    storeId: propTypeString.isRequired,
    listenToProductsChange: propTypeBool
  };

  BundleComponent.defaultProps = {
    listenToProductsChange: true
  };

  return BundleComponent;
};