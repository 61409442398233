import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Typography, Skeleton, Link, RatingMeter } from '@one-thd/sui-atomic-components';
import { Price } from '@thd-olt-component-react/price';
import { CustomSkeletonLine } from './CustomSkeletonLine';
import {
  getProductImage, onClickProductSpecifications, getIsMobile, isMajorAppliances
} from '../../utils/product-bundle-utils';
import { QuickViewLazy } from './QuickViewLazy';
import '../../styles/productListContent.scss';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {{bundleSpecificationDetails: {type: string}}} product Bundle product
 * @property {array} products - The number of products in the bundle
 * @property {string} channel - If it is Desktop or Mobile
 */

/**
 * Returns a formated Product List of the provided products.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const ProductListContent = ({
  products,
  product: bundleProduct,
  channel,
  accordionName,
  scrollStickyHeaderHeight
}) => {
  const isMobile = getIsMobile(channel);
  const [isQuickViewOpen, setIsQuickViewOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isRatingOpen, setIsRatingOpen] = useState(false);

  const openQuickView = (product) => {
    if (!isQuickViewOpen) {
      setIsQuickViewOpen(true);
      setSelectedProduct(product);
    }
  };

  const isMA = isMajorAppliances(bundleProduct);
  return (
    <ul data-testid="product-list-content" className="sui-w-full">
      {products?.length > 0
        ? products?.map((product) => {
          return (
            <li
              key={product?.itemId}
              // eslint-disable-next-line max-len
              className="product-list-item first:sui-pt-0 last:sui-pb-0 last:sui-border-0 sui-flex sui-items-start sui-border-b-1 sui-border-primary sui-border-solid sui-py-4"
              data-testid="product-list-item"
            >
              <div className="sui-w-24 sui-flex-auto sui-shrink-0 sui-grow-0 sui-pr-4">
                {
                  isMA ? (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <img
                      src={getProductImage(product?.media?.images)}
                      alt={product?.identifiers?.productLabel}
                      width="100%"
                      height="100%"
                      className="sui-w-full sui-h-full sui-object-contain hover:sui-cursor-pointer"
                      data-testid="product-list-product-image"
                      onClick={() => openQuickView(product)}
                    />
                  ) : (
                    <img
                      src={getProductImage(product?.media?.images)}
                      alt={product?.identifiers?.productLabel}
                      width="100%"
                      height="100%"
                      className="sui-w-full sui-h-full sui-object-contain"
                    />
                  )
                }
              </div>
              <div className="md:sui-grow md:sui-mr-auto sui-flex sui-flex-col md:sui-flex-row sui-w-full">
                <div className="sui-grow sui-pb-2">
                  {
                    isMA ? (
                      <Link
                        variant="body-base"
                        underline="hover"
                        onClick={() => openQuickView(product)}
                        data-testid="product-list-product-label"
                      >
                        <b>{product?.identifiers?.brandName}</b> <br />
                        {product?.identifiers?.productLabel}
                      </Link>
                    ) : (
                      <Typography variant="body-base" weight="bold">
                        {product?.identifiers?.brandName}
                      </Typography>
                    )
                  }
                  {
                    !isMA && (
                      <Typography lineClamp="2" variant="body-base" data-testid="product-list-product-label">
                        {product?.identifiers?.productLabel}
                      </Typography>
                    )
                  }
                  {
                    isMA && product?.identifiers?.modelNumber && (
                      <Typography variant="body-xs" color="subtle">
                        Model # {product.identifiers.modelNumber}
                      </Typography>
                    )
                  }
                  {
                    isMA && product?.reviews?.ratingsReviews && (
                      <RatingMeter
                        data-testid="product-list-item-rating-reviews"
                        className="hover:sui-cursor-pointer"
                        value={product?.reviews?.ratingsReviews?.averageRating}
                        label={`(${product?.reviews?.ratingsReviews?.totalReviews})`}
                        target="_blank"
                        edge="start"
                        RatingProps={{
                          color: 'brand'
                        }}
                        onClick={() => {
                          setIsRatingOpen(true);
                          openQuickView(product);
                        }}
                      />
                    )
                  }
                  <div className="sui-pt-3">
                    <Link
                      href={accordionName}
                      underline="always"
                      rel="noopener noreferrer"
                      onClick={(event) => onClickProductSpecifications({
                        event,
                        product,
                        accordionName,
                        isMobile,
                        scrollStickyHeaderHeight
                      })}
                    >
                      View Item Specifications
                    </Link>
                  </div>
                </div>
                <div
                  // eslint-disable-next-line max-len
                  className="productlist__price lg:sui-text-left md:sui-grow-0 md:sui-shrink-0 md:sui-basis-40 md:sui-pl-4"
                >
                  <Price
                    data-testid="product-list-item-price"
                    channel={channel}
                    itemId={product?.itemId}
                    large={false}
                    hideLimitPerOrder
                    displayEachUom={false}
                    clsRemediation={{
                      placeholders: true,
                      preservePlaceholders: true
                    }}
                    product={{
                      itemId: product.itemId,
                      identifiers: { itemId: product.itemId },
                      pricing: {
                        ...product?.pricing
                      }
                    }}
                  />
                </div>
              </div>
              {typeof window !== 'undefined' && isQuickViewOpen && (
                <Suspense fallback={<div />}>
                  <QuickViewLazy
                    isOpen={isQuickViewOpen}
                    onClose={() => {
                      setIsQuickViewOpen(false);
                      setSelectedProduct(null);
                      setIsRatingOpen(false);
                    }}
                    fetch={isQuickViewOpen}
                    itemId={selectedProduct?.itemId}
                    isRatingsOpen={isRatingOpen}
                    paginateCarousel={false}
                  />
                </Suspense>
              )}
            </li>
          );
        })
        : [...Array(5).keys()].map((item) => {
          return (
            <li
              key={item}
              // eslint-disable-next-line max-len
              className="product-list-item first:sui-pt-0 last:sui-pb-0 last:sui-border-0 sui-flex sui-items-start sui-border-b-1 sui-border-primary sui-border-solid sui-py-4"
              data-testid="product-list-item"
            >
              <div className="sui-w-full">
                <Skeleton grow disableGutters disablePadding>
                  <CustomSkeletonLine height="80px" />
                </Skeleton>
              </div>

            </li>
          );
        })}
    </ul>
  );
};

ProductListContent.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      itemId: PropTypes.string,
      dataSource: PropTypes.string,
      reviews: PropTypes.shape({
        ratingsReviews: PropTypes.shape({
          averageRating: PropTypes.string,
          totalReviews: PropTypes.string
        })
      }),
      identifiers: PropTypes.shape({
        brandName: PropTypes.string,
        productLabel: PropTypes.string,
        modelNumber: PropTypes.string
      }),
      media: PropTypes.shape({
        images: PropTypes.arrayOf(
          PropTypes.shape({
            sizes: PropTypes.arrayOf(PropTypes.string),
            url: PropTypes.string
          })
        )
      })
    })
  ),
  product: PropTypes.shape({
    bundleSpecificationDetails: PropTypes.shape({
      type: PropTypes.string
    })
  }),
  channel: PropTypes.string.isRequired,
  accordionName: PropTypes.string,
  scrollStickyHeaderHeight: PropTypes.number.isRequired
};

ProductListContent.defaultProps = {
  products: undefined,
  product: undefined,
  accordionName: null
};

ProductListContent.displayName = 'ProductListContent';

export { ProductListContent };
