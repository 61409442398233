import { useState, useEffect } from 'react';
import {
  updatePriceComponent,
  getBundleSummaryCalculations
} from '../utils/product-bundle-utils';

const useBundleSummaryCalculations = (itemId, adjustedPricing, maMinAdvertisedPriceExists, deliveryData) => {
  const [calculations, setCalculations] = useState(null);

  useEffect(() => {
    if (adjustedPricing) {
      const bundleSummaryCalculations = getBundleSummaryCalculations(adjustedPricing, (deliveryData?.deliveryFee || 0));

      updatePriceComponent(
        bundleSummaryCalculations?.bundleSummaryPrice,
        bundleSummaryCalculations?.totalDollarOff,
        bundleSummaryCalculations?.totalPercentOff,
        maMinAdvertisedPriceExists,
        bundleSummaryCalculations?.finalDiscountedPriceWithDeliveryFee,
        itemId
      );
      setCalculations(bundleSummaryCalculations);
    }
  }, [adjustedPricing, deliveryData, itemId]);

  return calculations;
};

export default useBundleSummaryCalculations;
