import React from 'react';
import PropTypes, { shape as shapeProp } from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { isMajorAppliances } from '../../utils/product-bundle-utils';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {number} productsCount - The number of products in the bundle
 */

/**
 * Returns a formated Title.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const ProductListTitle = ({ productsCount, product }) => {
  const listProductTitle = isMajorAppliances(product)
    ? `Appliances in this package (${productsCount})` : `Items in this bundle (${productsCount})`;

  return (
    <Typography variant="body-lg" weight="bold" data-testid="product-list-header">
      {listProductTitle}
    </Typography>
  );
};

ProductListTitle.propTypes = {
  productsCount: PropTypes.number.isRequired,
  product: shapeProp()
};

ProductListTitle.defaultProps = {
  product: null
};

ProductListTitle.displayName = 'ProductListTitle';

export { ProductListTitle };
