import React, { useContext, useEffect } from 'react';
import {
  object,
  arrayOf as arrayOfProp,
  shape as shapeProp,
  number as propTypeNumber,
  string as propTypeString
} from 'prop-types';
import {
  params, string, arrayOf, shape, extend, number, bool
} from '@thd-nucleus/data-sources';
import { Price } from '@thd-olt-component-react/price';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Skeleton
} from '@one-thd/sui-atomic-components';
import { CustomSkeletonLine } from './subcomponents/CustomSkeletonLine';
import { ProductListContent } from './subcomponents/ProductListContent';
import { ProductListTitle } from './subcomponents/ProductListTitle';

/**
 * Props
 *
 * @typedef {object} Props
 * @property {{bundleSpecificationDetails: object}} product Bundle product
 * @property {array} products Array of Products
 *  * @typedef {object} accordionProps
 *  * @property {string} accordionName Accordion name to open and close from parent accordion group
 *  * @property {number} scrollStickyHeaderHeight Height used to offset the scrolling action
 */

/**
 * BundleProductList component will be returned, this will show a list of products with image, description and price.
 * @param {Props} Props
 * @returns {React.ReactElement} JSX
 */

const BundleProductList = ({
  product,
  products,
  accordionProps
}) => {
  const experienceContext = useContext(ExperienceContext);
  const { channel } = experienceContext;
  const { accordionName, scrollStickyHeaderHeight } = accordionProps;

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('list-products.ready');
  }, []);

  return (
    <div
      data-testid="product-list"
      className="*:sui-border-solid before:sui-border-solid after:sui-border-solid sui-w-full sui-br-1 sui-border-x-1"
    >
      <Accordion defaultExpanded>
        <AccordionHeader aria-controls="accordion1-content" id="accordion-productlist-header">
          {products?.length > 0 ? (
            <ProductListTitle productsCount={products?.length} product={product} />
          ) : (
            <div className="sui-w-full">
              <Skeleton grow disableGutters disablePadding>
                <CustomSkeletonLine height="25px" />
              </Skeleton>
            </div>
          )}
        </AccordionHeader>
        <AccordionBody>
          <ProductListContent
            channel={channel}
            products={products}
            product={product}
            accordionName={accordionName}
            scrollStickyHeaderHeight={scrollStickyHeaderHeight}
          />
        </AccordionBody>
      </Accordion>
    </div>
  );
};

BundleProductList.propTypes = {
  product: shapeProp(),
  products: arrayOfProp(shapeProp({ object })),
  accordionProps: shapeProp({
    accordionName: propTypeString,
    scrollStickyHeaderHeight: propTypeNumber
  })
};

BundleProductList.defaultProps = {
  products: null,
  product: null,
  accordionProps: shapeProp({
    accordionName: null,
    scrollStickyHeaderHeight: 0
  })
};

BundleProductList.displayName = 'BundleProductList';

BundleProductList.dataModel = extend(
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      media: shape({
        images: arrayOf({
          sizes: arrayOf(string()),
          subType: string(),
          type: string(),
          url: string()
        })
      }),
      identifiers: shape({
        brandName: string(),
        productLabel: string(),
        modelNumber: string()
      }),
      reviews: shape({
        ratingsReviews: shape({
          averageRating: string(),
          totalReviews: string()
        })
      }),
      pricing: params({ storeId: string() }).shape({
        original: number({ float: true }),
        mapAboveOriginalPrice: bool(),
        promotion: shape({
          type: string(),
          description: shape({
            shortDesc: string(),
            longDesc: string()
          }),
          dollarOff: number({ float: true }),
          percentageOff: number({ float: true }),
          savingsCenter: string(),
          savingsCenterPromos: string(),
          specialBuySavings: string(),
          specialBuyDollarOff: string(),
          specialBuyPercentageOff: string(),
          experienceTag: string(),
          promotionTag: string(),
          subExperienceTag: string()
        }),
        specialBuy: number({ float: true }),
        value: number({ float: true }),
        conditionalPromotions: arrayOf({
          reward: shape({
            tiers: arrayOf({
              minPurchaseAmount: string(),
              rewardAmountPerOrder: string()
            })
          })
        })
      })
    })
  },
  Price
);

export { BundleProductList };
