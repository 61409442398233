/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import { StickyNav } from '@thd-olt-component-react/sticky-nav';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { isMajorAppliances, checkIfKitchenBundle } from '../utils/product-bundle-utils';
/**
 * Props
 *
 * @typedef {object} Props
 * @property {object} product - product information
 * @property {string} itemId - Id selected from the product
 */

/**
 * isHDPPSku - Indicates whether the SKU is an HDPP SKU.
 * @type {boolean}
 */

/**
 * hideRatingsIfHDPPSku - Indicates whether ratings are hidden or not
 * @type {boolean}
 */

/**
 * accordionsRefs - Object containing references to various DOM elements.
 * @typedef {Object} Refs
 * @property {React.RefObject} productOverviewRef - Reference to the product overview element.
 * @property {React.RefObject} specificationsRef - Reference to the specifications element.
 * @property {React.RefObject} questionsRef - Reference to the questions element.
 * @property {React.RefObject} ratingsRef - Reference to the ratings element.
 * @property {React.RefObject} stickyNavRef - Reference to the sticky Nav
 */

/**
 * isMA-  Indicates whether the product is classified as Major Appliances.
 * @type {boolean}
 */
const BundleStickyNav = ({
  itemId, isHDPPSku, hideRatingsIfHDPPSku, product, accordionsRefs
}) => {
  const isMA = isMajorAppliances(product);
  const hideIfKitchenPackage = checkIfKitchenBundle(product);

  const handleAccordionChange = (accordionId) => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-bundle.open-accordion', accordionId);
  };

  const updateStickyHeader = () => {
    const el = document.querySelector('.Header3');
    if (!el) {
      return;
    }
    el.classList.remove('Header3-sticky-desktop');
  };
  useEffect(() => {
    updateStickyHeader();
  }, []);

  return (
    <div data-testid="bundle-sticky-nav">
      <StickyNav
        itemId={itemId}
        ref={accordionsRefs.stickyNavRef}
        ratingsRef={isHDPPSku ? null : accordionsRefs.ratingsRef}
        onRatingsClick={() => handleAccordionChange('#product-section-rr')}
        hideIfHDPPSku={hideRatingsIfHDPPSku}
        hideStarRatings={hideIfKitchenPackage}
      >
        <StickyNav.Links>
          <StickyNav.Link targetRef={accordionsRefs.productOverviewRef}>
            <div
              data-testid="navlink-pso"
              onClick={() => handleAccordionChange('#product-section-overview')}
              role="button"
              tabIndex={0}
            >
              Product Details
            </div>
          </StickyNav.Link>
          <StickyNav.Link targetRef={accordionsRefs.specificationsRef}>
            <div
              data-testid="navlink-specs"
              onClick={() => handleAccordionChange('#product-section-key-feat')}
              role="button"
              tabIndex={0}
            >
              Specifications
            </div>
          </StickyNav.Link>
          {!isMA && (
            <StickyNav.Link targetRef={accordionsRefs.questionsRef}>
              <div
                data-testid="navlink-qa"
                onClick={() => handleAccordionChange('#product-section-qa')}
                role="button"
                tabIndex={0}
              >
                Questions & Answers
              </div>
            </StickyNav.Link>
          )}
          {!isMA && !isHDPPSku && (
            <StickyNav.Link targetRef={accordionsRefs.ratingsRef}>
              <div
                className="navlink-rr"
                onClick={() => handleAccordionChange('#product-section-rr')}
                role="button"
                tabIndex={0}
                data-testid="navlink-reviews"
              >
                Customer Reviews
              </div>
            </StickyNav.Link>
          )}
        </StickyNav.Links>
      </StickyNav>
    </div>
  );
};
BundleStickyNav.propTypes = {
  itemId: PropTypes.string,
  isHDPPSku: PropTypes.bool,
  hideRatingsIfHDPPSku: PropTypes.bool,
  product: PropTypes.object,
  accordionsRefs: PropTypes.shape({
    productOverviewRef: PropTypes.shape({ current: PropTypes.shape({}) }),
    specificationsRef: PropTypes.shape({ current: PropTypes.shape({}) }),
    questionsRef: PropTypes.shape({ current: PropTypes.shape({}) }),
    ratingsRef: PropTypes.shape({ current: PropTypes.shape({}) }),
    stickyNavRef: PropTypes.shape({ current: PropTypes.shape({}) })
  })
};

BundleStickyNav.defaultProps = {
  itemId: null,
  isHDPPSku: false,
  hideRatingsIfHDPPSku: true,
  product: null,
  accordionsRefs: {}
};

BundleStickyNav.displayName = 'BundleStickyNav';

BundleStickyNav.dataModel = extend(StickyNav);

export { BundleStickyNav };
