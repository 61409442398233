import { useState, useEffect } from 'react';
import {
  getAtcDisabled,
  getProductsFulfillmentInformation,
  getProductsMissingFulfillmentOptions,
  getAreAnyProductsOOS,
  getFulfillmentAvaiable
} from '../utils/product-bundle-utils';

const useProductsFullfilmentValidation = ({ products, bundleQuantity, deliveryZip, storeZip }) => {
  const [bundleQuantityLimit, setBundleQuantityLimit] = useState(2);
  const [disableATC, setDisableATC] = useState(true);
  const [productsFulfillmentInformation, setProductsFulfillmentInformation] = useState(null);
  const [currentlyOOS, setCurrentlyOOS] = useState(null);
  const [fulfillmentUnavailable, setFulfillmentUnavailable] = useState(false);

  useEffect(() => {
    if (products) {
      const productsFulfillmentData = getProductsFulfillmentInformation({
        products,
        deliveryZip,
        storeZip
      });

      setProductsFulfillmentInformation(productsFulfillmentData);

      const areAnyProductsOOS = getAreAnyProductsOOS({ products });

      setCurrentlyOOS(areAnyProductsOOS || productsFulfillmentData?.cartingOptions?.backordered);

      const isFulfillmentAvailable = getFulfillmentAvaiable({ products });

      setFulfillmentUnavailable(!isFulfillmentAvailable);

      setBundleQuantityLimit(productsFulfillmentData?.bundleMaxInventory);
    }
  }, [products]);

  useEffect(() => {

    const productsFulfillmentStatus = getProductsMissingFulfillmentOptions({ products });

    const isAtcDisabled = getAtcDisabled({
      productsFulfillmentOptionsStatus: productsFulfillmentStatus,
      quantityAvailable: productsFulfillmentInformation?.bundleMaxInventory >= bundleQuantity,
      backordered: productsFulfillmentInformation?.cartingOptions?.backordered
    });

    setDisableATC(isAtcDisabled);

  }, [bundleQuantity, products, productsFulfillmentInformation]);

  return {
    bundleQuantityLimit,
    disableATC,
    productsFulfillmentInformation,
    currentlyOOS,
    fulfillmentUnavailable
  };
};

export default useProductsFullfilmentValidation;
